import React, { useState, useEffect } from 'react';

const TextRenderer = ({ styledText }) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
        setDisplayText(styledText);
  }, [styledText]);

  // const parseStyledText = (text) => {
  //   // Parse the text and return JSX with styles
  //   const boldRegex = /\*(.*?)\*/g;
  //   const italicRegex = /_(.*?)_/g;
  //   const colorRegex = /%(.*?)%/g;
  //   const underlineRegex = /~(.*?)~/g;
  //   const sizeRegex = /\^(.*?)\^/g;

  //   return text
  //     .replace(boldRegex, '<strong>$1</strong>')
  //     .replace(italicRegex, '<em>$1</em>')
  //     .replace(colorRegex, '<span style="color:blue">$1</span>') // Example color
  //     .replace(underlineRegex, '<u>$1</u>')
  //     .replace(sizeRegex, '<span style="font-size:20px">$1</span>'); // Example size
  // };
  const parseStyledText = (text) => {
    // Parse the text and return JSX with styles and URLs
    const boldRegex = /\*([\s\S]*?)\*/g;
    const italicRegex = /_([\s\S]*?)_/g;
    const underlineRegex = /~([\s\S]*?)~/g;
    const highlightRegex = /==([\s\S]*?)==/g;
    const redTextRegex = /@@red([\s\S]*?)@@/g;
    const greenTextRegex = /@@green([\s\S]*?)@@/g;
    const urlRegex = /\[([\s\S]*?)\]\(([\s\S]*?)\)/g;
    const ulRegex = /- ([\s\S]*?)\n/g;



    const replaceBold = '<span style="font-weight: bold;">$1</span>';
    const replaceItalic = '<span style="font-style: italic;">$1</span>';
    const replaceUnderline = '<span style="text-decoration: underline;">$1</span>';
    const replaceHighlight = '<mark>$1</mark>';
    const replaceRedText = '<span style="color: red;">$1</span>';
    const replaceGreenText = '<span style="color: green;">$1</span>';
    const replaceUrl = '<a href="$2" style="color: blue; text-decoration: underline;" target="_blank">$1</a>';
    const replaceUl = '<ul style="margin-top: 0 !important; margin-bottom: 0 !important; padding-top: 0 !important; padding-bottom: 0 !important;"><li style="margin: 0; padding: 0;">$1</li></ul>';
    return text
      .replace(boldRegex, replaceBold)
      .replace(italicRegex, replaceItalic)
      .replace(underlineRegex, replaceUnderline)
      .replace(highlightRegex, replaceHighlight)
      .replace(redTextRegex, replaceRedText)
      .replace(greenTextRegex, replaceGreenText)
      .replace(urlRegex, replaceUrl)
      .replace(ulRegex, replaceUl);
  };
  return (
    <div>
      <div 
      style={{
        margin:'0px',
        textAlign: 'right',
        lineHeight: '1.5',
        padding: '0px',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word' 
      }}
      
      dir='rtl' dangerouslySetInnerHTML={{ __html: parseStyledText(displayText) }} />
    </div>
  );
};

export default TextRenderer;
