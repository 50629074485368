import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Hm from './comp/Hm';
import Chat from './comp/Chat';

function App() {
  return (
    <div className="app">
      
      <BrowserRouter>
     
          <Routes>
            <Route path="/bt" element={<Chat/>} />
            <Route path="/" element={<Hm/>} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
