import React, { useState, useEffect, useRef } from 'react';
import { IoIosSend } from 'react-icons/io';
import { BsToggleOn, BsToggleOff } from 'react-icons/bs';
import { GiCancel } from 'react-icons/gi';
import { url } from '../Config'
import axios from 'axios';
import './chatboot.css';
import TextRenderer from './TextRenderer';


const fmessages = [
  'مرحبا، كيف يمكنني مساعدتك اليوم؟',
  "مرحبًا ! أنا الدكتور فريد، كيف يمكنني مساعدتك اليوم؟",
  "مرحبًا! الدكتور فريد بخدمتك، كيف يمكنني مساعدتك اليوم؟",
  "مرحبًا بك! أنا الدكتور فريد، هل لديك أي أسئلة طبية أو استشارات تحتاجها اليوم؟ ",
];

const alertMessages = [
  "يرجى مراعاة أنه يمكنني توجيهك فقط بناءًا على المعرفة المتاحة لدي، وننصح دائمًا بمراجعة طبيبك للأمور الطبية الدقيقة",
  "يرجى العلم بأنه ليس لدي تصريح طبي لتقديم استشارات طبية دقيقة ، يمكنني توجيهك فقط بناءًا على المعرفة المتاحة لدي،",
  // "تذكير: أنا هنا للمساعدة والتوجيه بناءً على ما أعرف، وليس لدي تصريح طبي دقيق.",
  // "أرجو أن تضع في اعتبارك أنني قادر على تقديم إرشاد استنادًا إلى معرفتي المحدودة، ونوصي دائمًا بالتشاور مع طبيبك فيما يتعلق بالقضايا الطبية الدقيقة.",
  // "من فضلك، تذكر أنني قادر على توجيهك استنادًا إلى معرفتي المتاحة وليس لدي تصريح طبي دقيق.",
  // "يرجى أن تحتفظ بالعلم أنني استعرض المعرفة المتاحة وقدرتي على تقديم إرشاد. ليس لدي تصريح طبي دقيق.",
  // "يرجى مراعاة أنني غير مصرح لتقديم استشارات طبية دقيقة، ولكن بالتأكيد يمكنني مشاركة المعرفة المتاحة لدي لتوجيهك.",
];

const getRandomMessage = () => fmessages[Math.floor(Math.random() * fmessages.length)];
const getRandomAlertMessage = () => alertMessages[Math.floor(Math.random() * alertMessages.length)];

const FirstinitialMessage = {
  user: false,
  text: `${getRandomMessage()}`
};
const SecondeinitialMessage = {
  user: false,
  text: ` ${getRandomAlertMessage()}`
};


function Chat() {
  const [chatHistory, setChatHistory] = useState([]);
  const chatHistoryRef = useRef(null);
  const [chatMode, setChatMode] = useState(false);
  const [chatModebutton, setChatModebutton] = useState(false);
  const [catego, setcatego] = useState(null);
  const [recatego, setrecatego] = useState(null);
  const [sub, setSub] = useState(null);
  const [resub, setreSub] = useState(null);

  const [inputValue, setInputValue] = useState('');
  const [botTyping, setBotTyping] = useState(false);

  const [elapsedTime, setElapsedTime] = useState(null);
  const [elapsedTimewait, setElapsedTimewait] = useState(false);
  const [callQueue, setCallQueue] = useState([]);
  const [estimatedTotalTime, setEstimatedTotalTime] = useState(0);



  
  useEffect(() => {
    const fetchDataaa = async () => {
        await handleCallQueue();
    };

    fetchDataaa();
}, [elapsedTimewait]);


async function handleCallQueue() {
    if (elapsedTimewait || callQueue.length === 0) {
        return;
    }

    const { userMessage, botMessage, chatHistoryx } = callQueue[0];
    setCallQueue((prevQueue) => prevQueue.slice(1));

    // Trigger the next call after waiting for the estimated time
    setTimeout(async () => {
        //console.log(chatHistoryx);
        //console.log(Array.isArray(chatHistoryx));
        const iterableChatHistoryx = chatHistoryx || [];
        await typeAndDisplayBotResponse(userMessage, botMessage, [...chatHistory, ...iterableChatHistoryx]);

    }, estimatedTotalTime);
}


  // useEffect(() => {
  //   chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;

  //   if (chatMode === false && botTyping === false) {
  //     // Check if the screen width is above a certain threshold (e.g., 768px) before focusing
  //     if (window.innerWidth > 768) {
  //       // Focus on the input element
  //       const inputElement = document.querySelector('.chat-input input');
  //       if (inputElement) {
  //         inputElement.focus();
  //       }
  //     }
  //   }
  // }, [chatHistory, chatMode, botTyping]);

  useEffect(() => {
    if (botTyping) {
        chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
        chatHistoryRef.current.style.overflow = 'hidden';
    } else {
        chatHistoryRef.current.style.overflow = 'scroll';
        chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;

        if (window.innerWidth > 768) {
            const inputElement = document.querySelector('.chat-input input');
            if (inputElement) {
                inputElement.focus();
            }
        }
    }
}, [chatHistory, botTyping]);



  useEffect(() => {
    if (chatModebutton === true) {
      handleModeOption({ label: 'قف', value: false, type: 'mode' })
    }
  }, [chatModebutton])

  // useEffect(() => {
  //   chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
  //   if (chatMode === false  && botTyping === false) {
  //     // Focus on the input element
  //     const inputElement = document.querySelector('.chat-input input');
  //     if (inputElement) {
  //       inputElement.focus();
  //     }
  //   }
  // }, [chatHistory]);

  useEffect(() => {
    // Function to run the initial bot messages sequentially
    const runInitialBotMessages = async () => {
      setBotTyping(true);

      // First initial message
      await typeAndDisplayBotResponse('', FirstinitialMessage, chatHistory);

      const updatedChatHistoryWithInitialMsg = [
        ...chatHistory,
        FirstinitialMessage,
      ]

      // Second initial message
      await typeAndDisplayBotResponse('', SecondeinitialMessage, updatedChatHistoryWithInitialMsg);

      setBotTyping(false);
    };

    runInitialBotMessages();
  }, []);




  useEffect(() => {

    if (catego) {
      setrecatego(catego)
      handleCategoryOption({ value: catego, label: catego, type: 'category' });
    }
  }, [catego]);

  useEffect(() => {

    if (sub) {
      setreSub(sub)
      handleSubCategoryOption({ value: sub, label: catego, type: 'subcategory' });
    }
  }, [sub]);




  async function typeAndDisplayBotResponse(userMessage, botMessage, chatHistory) {
    const initialTypingAnimationDuration = 250; // Estimated duration for each iteration
    const initialTypingAnimationIterations = 2;
    const initialTypingAnimationTotalTime = initialTypingAnimationDuration * initialTypingAnimationIterations;

    const waitTwoSecondsDuration = 1900;

    const estimated =
        initialTypingAnimationTotalTime +
        waitTwoSecondsDuration +
        botMessage.text.length * 25;
    setEstimatedTotalTime(estimated);
    //console.log(`Estimated total time: ${estimated} milliseconds`);
    if (elapsedTimewait) {

        setCallQueue((prevQueue) => [...prevQueue, { userMessage, botMessage, chatHistory }]);
        return;
    }
    setElapsedTimewait(true);

    const startTime = performance.now();
    await setBotTyping(true)
    const updatedChatHistoryWithUserInput = [...chatHistory];

    if (userMessage !== '') {
        updatedChatHistoryWithUserInput.push({ user: true, text: userMessage.replace(/_/g, ' ') });
    }

    let displayedText = '';

    for (let i = 0; i < 2; i++) {
        let initialTypingAnimation = '';
        for (let j = 1; j <= 3; j++) {
            initialTypingAnimation += '.'.repeat(j);
            const updatedChatHistoryTypingInitial = [
                ...updatedChatHistoryWithUserInput,
                { user: false, text: initialTypingAnimation },
            ];
            setChatHistory(updatedChatHistoryTypingInitial);
            await new Promise((resolve) => setTimeout(resolve, 250));
        }
    }

    await new Promise((resolve) => setTimeout(resolve, 30));

    if (botMessage && botMessage.text && botMessage.type !== 'notify') { // Check if botMessage is defined and has a text property
        const characters = botMessage.text.split('');
        for (const char of characters) {
            await new Promise((resolve) => setTimeout(resolve, 25));
            displayedText += char;

            const updatedChatHistoryTyping = [
                ...updatedChatHistoryWithUserInput,
                {
                    ...botMessage,
                    user: false,
                    text: botMessage.type === 'notify' ? botMessage.text : displayedText,
                    type: botMessage.type !== null || botMessage.type !== undefined ? botMessage.type : null,
                    options: '[]',
                }
            ];
            setChatHistory(updatedChatHistoryTyping);
            localStorage.setItem('chatHistory', JSON.stringify(updatedChatHistoryTyping));

        }
    } else {
        const updatedChatHistoryTyping = [
            ...updatedChatHistoryWithUserInput,
            {
                ...botMessage,
                user: false,
                text: botMessage.type === 'notify' ? botMessage.text : botMessage.text,
                type: botMessage.type !== null || botMessage.type !== undefined ? botMessage.type : null,
                options: '[]',
            }
        ];
        setChatHistory(updatedChatHistoryTyping);
        localStorage.setItem('chatHistory', JSON.stringify(updatedChatHistoryTyping));
    }

    let updatedChatHistoryWithBotResponse = [
        ...updatedChatHistoryWithUserInput,
        {
            ...botMessage,
            user: false,
            type: botMessage.type !== null || botMessage.type !== undefined ? botMessage.type : null,
            options: '[]',
        }
    ];

    localStorage.setItem('chatHistory', JSON.stringify(updatedChatHistoryWithBotResponse));

    if (botMessage.options != undefined) {
        updatedChatHistoryWithBotResponse = [
            ...updatedChatHistoryWithUserInput,
            {
                ...botMessage,
                user: false,
                type: botMessage.type !== null || botMessage.type !== undefined ? botMessage.type : null,
                options: botMessage.options.length > 0 ? botMessage.options : '[]',
            }
        ];
    }

    setChatHistory(updatedChatHistoryWithBotResponse);
    localStorage.setItem('chatHistory', JSON.stringify(updatedChatHistoryWithBotResponse));
    setBotTyping(false)
    const endTime = performance.now();
    const elapsedTime = endTime - startTime;
    //console.log(`Animation completed in ${elapsedTime} milliseconds`);
    //console.log(callQueue);
    setElapsedTime(elapsedTime);
    setEstimatedTotalTime(0);
    setElapsedTimewait(false);
}


  // async function typeAndDisplayBotResponse(userMessage, botMessage, chatHistory) {
  //   const updatedChatHistoryWithUserInput = [...chatHistory];
  //   setBotTyping(true);
  //   if (userMessage !== '') {
  //     updatedChatHistoryWithUserInput.push({ user: true, text: userMessage.replace(/_/g, ' ') });
  //   }
  //   let displayedText = '';

  //   // Display moving three dots in a loop
  //   for (let i = 0; i < 2; i++) {
  //     let initialTypingAnimation = '';
  //     for (let j = 1; j <= 3; j++) {
  //       initialTypingAnimation += '.'.repeat(j);
  //       const updatedChatHistoryTypingInitial = [
  //         ...updatedChatHistoryWithUserInput,
  //         { user: false, text: initialTypingAnimation },
  //       ];
  //       setChatHistory(updatedChatHistoryTypingInitial);
  //       await new Promise((resolve) => setTimeout(resolve, 150));
  //     }
  //   }

  //   // Wait for two seconds
  //   await new Promise((resolve) => setTimeout(resolve, 150));

  //   // Replace the three dots with the actual bot message
  //   const characters = botMessage.text.split('');
  //   for (const char of characters) {
  //     await new Promise((resolve) => setTimeout(resolve, 25));
  //     displayedText += char;

  //     const updatedChatHistoryTyping = [
  //       ...updatedChatHistoryWithUserInput,
  //       { user: false, text: displayedText },
  //     ];
  //     setChatHistory(updatedChatHistoryTyping);
  //   }

  //   let updatedChatHistoryWithBotResponse = [
  //     ...updatedChatHistoryWithUserInput,
  //     { user: false, text: botMessage.text },
  //   ];

  //   if (botMessage.options != undefined) {
  //     updatedChatHistoryWithBotResponse = [
  //       ...updatedChatHistoryWithUserInput,
  //       {
  //         user: false,
  //         text: botMessage.text,
  //         options:
  //           botMessage.options.length > 0 ? botMessage.options : '[]',
  //       },
  //     ];
  //   }

  //   setChatHistory(updatedChatHistoryWithBotResponse);
  //   setBotTyping(false);
  // }






  async function getBotResponse(userMessage) {
    //console.log(userMessage.replace(/[.,\/#!?$%\^&\*;:{}=\-`~()،؛²؟]/g, '').trim().split(' ').length)
    //console.log(userMessage.replace(/[.,\/#!?$%\^&\*;:{}=\-`~()،؛؟]/g, '').trim().split(' '))
    try {

      const res = await axios.post(`${url}/bot/free-chat/response`, {
        userMessage: userMessage,
      });

      const botMessage = res.data.botMessage;

      //console.log('tttttttttttttttttttttttttt:', botMessage.text, 'tr')
      //console.log(botMessage.options)


      if (botMessage.text.trim() === '' && userMessage.replace(/[.,\/#!?$%\^&\*;:{}=\-`~()،؛؟]/g, '').trim().split(' ').length > 2) {
        const messages = [
          "عذرًا، ليس لدي الإجابة على هذا السؤال في الوقت الحالي. يمكنني المساعدة في أمور أخرى إذا كنت ترغب.",
          "أعتذر، ليس لدي معلومات حاليًا حول هذا السؤال.  يمكنني المساعدة في أمور أخرى!",
          "عذرًا، ليس لدي الإجابة على هذا السؤال الآن. هل تحتاج إلى مساعدة في أي موضوع آخر؟"
        ];

        const randomMessage = messages[Math.floor(Math.random() * messages.length)];

        let messg = {
          text: randomMessage,
          options: JSON.stringify([
            { label: 'نعم', value: true, type: 'mode' },
            { label: 'لا', value: false, type: 'mode' },
          ]),
        };
        await typeAndDisplayBotResponse(userMessage, messg, chatHistory);
      } else if (botMessage.text.trim() === '' && userMessage.replace(/[.,\/#!?$%\^&\*;:{}=\-`~()،؛؟]/g, '').trim().split(' ').length <= 2) {
        const messages = [
          "عذرًا، يمكنك تحديد فكرتك جيدًا!",
          `أتقصد شيئا بـ ${userMessage}؟`,
        ];

        const randomMessage = messages[Math.floor(Math.random() * messages.length)];

        let messg = { text: randomMessage };
        await typeAndDisplayBotResponse(userMessage, messg, chatHistory);
      } else {

        await typeAndDisplayBotResponse(userMessage, botMessage, chatHistory);

      }
    } catch (error) {
      console.error('Error fetching bot response:', error);
    }
  }


  function processUserMessageWithWordCount(userMessage) {
    if (userMessage.split(' ').length >= 2 && userMessage.match(/[\u0600-\u06FF\s]/)) {
      const words = userMessage.split(' ');

      // Count the occurrences of each word
      const wordCounts = {};
      for (const word of words) {
        const cleanedWord = word.trim(); // Trim leading/trailing spaces
        if (wordCounts[cleanedWord]) {
          wordCounts[cleanedWord]++;
        } else {
          wordCounts[cleanedWord] = 1;
        }
      }

      // Find the most common word
      let mostCommonWord = null;
      let highestCount = 0;
      for (const word in wordCounts) {
        if (wordCounts[word] > highestCount) {
          mostCommonWord = word;
          highestCount = wordCounts[word];
        }
      }

      // Check if the most common word's occurrence is within the desired range
      if (mostCommonWord && highestCount / words.length >= 0.6 && highestCount / words.length < 1) {
        const rephrasingMessages = [
          `انا هنا لمساعدتك اعد صياغة سؤالك هذه المرة دون تكرار كلمة '${mostCommonWord}'؟`,
          `هل يمكنك إعادة صياغة سؤالك بدون تكرار كلمة '${mostCommonWord}'؟`,
          `هل تقصد ${mostCommonWord} بشكل خاص؟`,
          // Add more rephrasing messages here
        ];

        return rephrasingMessages[Math.floor(Math.random() * rephrasingMessages.length)];
      } else if (mostCommonWord && highestCount / words.length === 1) {
        const clarificationMessages = [
          `أتقصد شيئا بـ ${mostCommonWord}؟`,
          `هل يمكنك إعادة صياغة سؤالك بدون تكرار كلمة '${mostCommonWord}'؟`,
          `هل تقصد ${mostCommonWord} بشكل خاص؟`,
          // Add more clarification messages here
        ];

        return clarificationMessages[Math.floor(Math.random() * clarificationMessages.length)];
      }

      return null;
    } else {
      return null;
    }
  }


  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };



  async function handleSubmit() {
    // if (window.innerWidth <= 768) {
    //   // Check if the screen width is below or equal to the threshold (e.g., 768px) for phone screens
    //   // Blur the input element to remove focus and hide the keyboard
    //   const inputElement = document.querySelector('.chat-input input');
    //   if (inputElement) {
    //     inputElement.blur();
    //   }
    // }
    setChatModebutton(false)
    setBotTyping(true);
    setcatego(null);
    setSub(null);

    const userMessage = inputValue.trim();
    setInputValue('');
    const result = processUserMessageWithWordCount(userMessage);

    if (userMessage !== '') {
      let botMessage = '';
      let lastBotMessage = '';
      let lastUserMessage = '';

      if (chatHistory.length >= 3) {
        lastBotMessage = chatHistory[chatHistory.length - 1];
        lastUserMessage = chatHistory[chatHistory.length - 2];
      }

      // console.log(lastBotMessage, lastUserMessage);
      switch (true) {
        case userMessage.length > 250:
          const tooLongMessages = [
            "هذه رسالة طويلة جدًا، هل تستطيع تقديم ملخص أو سؤال مختصر؟",
            "نعتذر، الرسالة طويلة جدًا. هل بإمكانك تقديم ملخص أو سؤال مختصر؟",
          ];
          botMessage = {
            text: tooLongMessages[Math.floor(Math.random() * tooLongMessages.length)],
          };
          break;

        // case /^[^\p{L}\p{N}]+$/u.test(userMessage):
        case /^[^\p{L}\p{N}\u00B2]+$/u.test(userMessage):
          const nonAlphaNumMessages = [
            "لقد ذكرت رموز ترقيمية. كيف يمكنني مساعدتك؟",
            "يبدو أنك تستخدم رموز ترقيمية، هل تحتاج إلى مساعدة في شيء؟",
            "يمكنك توضيح المزيد عن الرموز التي تتحدث عنها أو طرح أي سؤال لديك؟ سأكون سعيدًا بمساعدتك عندما تقدم مزيدًا من المعلومات.",
          ];
          botMessage = {
            text: nonAlphaNumMessages[Math.floor(Math.random() * nonAlphaNumMessages.length)],
          };
          break;

        case /^\d+$/.test(userMessage):
          const numbersMessages = [
            "لقد ذكرت أرقام. كيف يمكنني مساعدتك؟",
            "عفوا هناك العديد من الأرقام ، فقط قدم لي المزيد من المعلومات حول ما تحتاجه، وسأقوم بمساعدتك بكل سرور.",
            "يبدو أنك تستخدم أرقام، هل تحتاج إلى مساعدة فيما يتعلق بها؟",
            "يمكنك توضيح المزيد عن الأرقام التي تتحدث عنها أو طرح أي سؤال لديك؟ سأكون سعيدًا بمساعدتك عندما تقدم مزيدًا من المعلومات.",
          ];
          botMessage = {
            text: numbersMessages[Math.floor(Math.random() * numbersMessages.length)],
          };
          break;

        case userMessage.match(/[^\p{L}\p{N}\s\u00B2]/gu) && userMessage.match(/\d+/g) && userMessage.match(/\p{L}+/gu) === null:
          const mixedMessages = [
            "لقد ذكرت رموز ترقيمية وأرقام. كيف يمكنني مساعدتك؟",
            "الرسالة تحتوي على مزيج من الأرقام والرموز الترقيمية. هل تحتاج إلى مساعدة مع هذا؟",
            "يمكنك توضيح المزيد عن المزيج من الأرقام والرموز الترقيمية التي تتحدث عنها أو طرح أي سؤال لديك؟ سأكون سعيدًا بمساعدتك عندما تقدم مزيدًا من المعلومات.",
            "عذرًا، لا يمكنني فهم لغتك. من فضلك، قدم سؤالك أو استفسارك باللغة العربية",

          ];
          botMessage = {
            text: mixedMessages[Math.floor(Math.random() * mixedMessages.length)],
          };
          break;

        // case /^[A-Za-z0-9\s\p{P}]+$/u.test(userMessage):
        case /^[A-Za-z^¨À-ÿïçôêûzäöüÄÖÜßéÉèÈç0-9\s\p{P}]+$/u.test(userMessage):
          const englishMessage = [
            "تحدث بالعربية إذا سمحت!",
            "نحن هنا لمساعدتك باللغة العربية. كيف يمكننا مساعدتك؟",
            "عذرًا، لا يمكنني فهم لغتك. من فضلك، قدم سؤالك أو استفسارك باللغة العربية",
            "عفوا أجد صعوبة في فهم لغتك، يمكنك استخدام خدمات الترجمة للمساعدة في التفاعل باللغة العربية.",
          ];
          botMessage = {
            text: englishMessage[Math.floor(Math.random() * englishMessage.length)],
          };
          break;

        case result !== null:
          botMessage = {
            text: result,
          };
          break;
        case (userMessage === 'نعم' || userMessage === 'اه'):
          const yesResponses = [
            `من الجيد أن أسمع ذلك! أنا دائمًا هنا لمساعدتك. ما هو سؤالك؟`,
            `رائع! أنا مستعد للمساعدة. ماذا تحتاج؟`,
            `أنا سعيد بذلك! كيف يمكنني مساعدتك اليوم؟`,
          ];
          const randomYesResponse = yesResponses[Math.floor(Math.random() * yesResponses.length)];
          botMessage = {
            text: randomYesResponse,
          };
          break;
        case userMessage === 'لا':


          let noResponses = [];

          if (chatHistory.length >= 3) {
            const truncateText = (text) => {
              const words = text.split(' ');
              const truncatedWords = words.slice(0, 3);
              const truncatedText = truncatedWords.join(' ');
              return truncatedText;
            };
  
            const truncatedLastBotMessage = truncateText(lastBotMessage.text);
            const truncatedLastUserMessage = truncateText(lastUserMessage.text);
            noResponses = [
              // `عذرًا!! لكن لماذا "لا"؟ 
              // أنت أرسلت لي : ${truncatedLastUserMessage}... 
              // وأنا أجبتك بـ : ${truncatedLastBotMessage}... 
              // غريب! إذاً إذا كان هذا غير جيد، الرجاء إرسال كلمة "وجهني"، "ارشدني"، "توجيه" أو "ارشاد" للحصول على معلومات أكثر.`,
              // `عذرًا، لم أفهم سبب الرفض. يمكنك توضيح المزيد؟ 
              // أنت أرسلت لي : ${truncatedLastUserMessage}... 
              // وأنا أجبتك بـ : ${truncatedLastBotMessage}... 
              // إذا كنت بحاجة إلى مساعدة، يرجى إرسال كلمة "توجيه" .`,
//               `عذرًا، لم أفهم سبب الرفض. يمكنك توضيح المزيد؟ 
// لماذا 'لا'! لقد أجبتك على رسالتك السابقة : ${truncatedLastUserMessage}.. 
// بـ : ${truncatedLastBotMessage}.....! 
// حسنًا، إذا كنت تحتاج إلى أي مساعدة، لا تتردد في سؤالي!
// أو يرجى إرسال كلمة "ارشدني" للحصول على معلومات أكثر.`,
              `عذرًا إذا كانت الإجابة غير مرضية. يرجى إرسال كلمة  "ارشاد" للحصول على معلومات أكثر.`,
              `نأسف إذا لم تكن الإجابة ما تشتهيه. يرجى إرسال كلمة "وجهني" للحصول على معلومات إضافية.`,
              `عذرًا إذا لم يكن الرد كما تتوقع. يرجى إرسال كلمة " توجيه" إذا كنت تحتاج إلى مساعدة إضافية.`,
            ];
          } else {
            noResponses = [
              // `حسنًا، إذا كنت تحتاج إلى أي مساعدة، لا تتردد في سؤالي.`,
              // `مفهوم، إذا كنت تحتاج إلى أي مساعدة في أي وقت ، أنا هنا.`,
              // `حسنًا، إذا لديك أي استفسارات أخرى ، لا تتردد في سؤالي.`,
              `عذرًا إذا كانت الإجابة غير مرضية. يرجى إرسال كلمة  "ارشاد" للحصول على معلومات أكثر.`,
              `نأسف إذا لم تكن الإجابة ما تشتهيه. يرجى إرسال كلمة "وجهني" للحصول على معلومات إضافية.`,
              `عذرًا إذا لم يكن الرد كما تتوقع. يرجى إرسال كلمة " توجيه" إذا كنت تحتاج إلى مساعدة إضافية.`,
            ];
          }

          const randomNoResponse = noResponses[Math.floor(Math.random() * noResponses.length)];
          botMessage = {
            text: randomNoResponse,
          };
          break;
        case (userMessage === 'وجهني' || userMessage === 'توجيه' || userMessage === 'ارشدني' || userMessage === 'ارشاد'):
          try {
            const res = await axios.get(`${url}/bot/directed-chat/categories-name`);
            const categoryop = res.data.categoryNames.map((category) => ({
              label: category,
              value: category,
              type: 'category',
            }));
            const botResponses = [
              'بالطبع، في أي موضوع تريد التحدث؟',
              'مرحبًا! أنا هنا لمساعدتك. ما هو موضوع استفسارك؟',
              'الرجاء تحديد الموضوع الذي ترغب في مناقشته.',
              'مرحبًا! للحصول على مساعدتي، يُرجى الاطلاع على القائمة التالية',
              'مرحبًا! للاستفادة من خدماتي، يرجى الاطلاع على القائمة أدناه'
            ];

            const randomResponse = botResponses[Math.floor(Math.random() * botResponses.length)];

            botMessage = { text: randomResponse, options: JSON.stringify(categoryop) };
          } catch (err) {
            //console.log(err);
          }
          setChatMode(true);
          break;

        default:
          setChatHistory((prevChatHistory) => [
            ...prevChatHistory,
            { user: true, text: userMessage },
          ]);
          setInputValue('');
          setBotTyping(true);

          const animationPromise = new Promise(async (resolve) => {
            for (let i = 0; i < 2; i++) {
              let initialTypingAnimation = '';
              for (let j = 1; j <= 3; j++) {
                initialTypingAnimation += '.'.repeat(j);
                setChatHistory([
                  ...chatHistory,
                  { user: true, text: userMessage },
                  { user: false, text: initialTypingAnimation },
                ]);
                await new Promise((innerResolve) => setTimeout(innerResolve, 300));
              }
              resolve();
            }
          });

          animationPromise.then(() => {
            getBotResponse(userMessage);
            setBotTyping(false);
          });
      }

      if (botMessage !== '') {
        await typeAndDisplayBotResponse(userMessage, botMessage, chatHistory);
      }
    }
    setBotTyping(false);
  }



  const handleOptionClick = async (option) => {
    //console.log('optionnnnnnnnnnnnnnn',option)
    //console.log('ctaego',catego)
    //console.log('sub',sub)
    //console.log('recatego',recatego)
    //console.log('resub',resub)

    switch (option.type) {
      case 'mode':
        handleModeOption(option);
        break;
      case 'category':
        if (option.value) {
          await setcatego(option.value);
        }
        break;
      case 'subcategory':
        if (option.value) {
          await setSub(option.value);
        }
        break;
      case 'recatego':
        handleCategoryOption(option)
        break;
      case 'resub':
        handleSubCategoryOption(option)
        break;
      case 'messagez':
        handleMessageOption(option);
        setcatego(null)
        setSub(null)
        break;
      case 'link':
        handleLink(option);
        setcatego(null)
        setSub(null)
        tali(option)
        break;
      case 'text':
        handletextoption(option);
        break;
      default:
        setcatego(null)
        setSub(null)
        tali(option)
        break;
    }
  };


  const tali = async (option) => {
    // if (option.type ==='link' || option.type ==='messagez') {
    const messages = [
      'إذا كان لديك أي أسئلة أو استفسارات، فلا تتردد في طرحها؟',
      'أنا هنا لمساعدتك! هل لديك أي أسئلة أخرى؟',
      'أنا هنا لمساعدتك بأي شيء آخر، هل لديك استفسار آخر؟'
    ];

    const randomMessage = messages[Math.floor(Math.random() * messages.length)];

    let messg = {
      text: randomMessage,
      // options: JSON.stringify([
      //   { label: 'نعم', value: true, type: 'mode' },
      //   { label: 'لا', value: false, type: 'mode' },
      // ]),
    };

    await typeAndDisplayBotResponse(option.label, messg, chatHistory);
    // } else {
    //   const messages = [
    //     "هل تحتاج المزيد من المساعدة؟",
    //     "هل تود الاستمرار؟",
    //     "إذا لم تكن هذه الخطوات كافية أو غير مرضية، يُرجى النقر مجددًا على 'نعم'",
    //     "إذا كنت بحاجة إلى المزيد من المساعدة، انقر 'نعم' مرة أخرى.",
    //     "شكرًا لاختيارك هذه الخيارات. إذا كنت بحاجة للمزيد من المساعدة، انقر 'نعم'.",
    //     "نحن هنا لمساعدتك، انقر 'نعم' إذا كنت بحاجة إلى المزيد من المعلومات.",
    //   ];

    //   const randomMessage = messages[Math.floor(Math.random() * messages.length)];

    //   let messg = {
    //     text: randomMessage,
    //     options: JSON.stringify([
    //       { label: 'نعم', value: true, type: 'mode' },
    //       { label: 'لا', value: false, type: 'mode' },
    //     ]),
    //   };

    //   await typeAndDisplayBotResponse(option.label, messg, chatHistory);
    // }
  }


  const handleCategoryOption = async (option) => {
    if (!option.value) {
      return;
    }

    // await setcatego(option.value);
    // console.log('ffffffff', option.value)
    // console.log('ffffffff', catego)

    try {
      // console.log('catego:', catego);
      const res = await axios.get(`${url}/bot/directed-chat/subcategories/${option.value}`);
      // console.log(res);
      const subcategoryop = res.data.subcategories.map((subcategory) => ({
        label: subcategory,
        value: subcategory,
        type: 'subcategory',
      }));
      // const botResponse = `في أي موضوع من ${option.label} ترغب في الحديث؟`;
      const botResponses = [
        `من فضلك، ما الذي تريد معرفته حول ${option.value.replace(/_/g, ' ')}`,
        `كيف يمكنني مساعدتك فيما يتعلق بـ ${option.value.replace(/_/g, ' ')}؟`,
      ];

      const randomResponse = botResponses[Math.floor(Math.random() * botResponses.length)];

      let messg = { text: randomResponse, options: JSON.stringify(subcategoryop) };
      await typeAndDisplayBotResponse(option.value, messg, chatHistory);


    } catch (err) {
      //console.log(err);
    }
  };

  const handleSubCategoryOption = async (option) => {
    if (!option.value) {
      return;
    }

    try {
      // console.log('Subcategory option clicked:', option.value);
      // console.log('sub:', sub);

      const res = await axios.get(`${url}/bot/directed-chat/messages/${catego !== null ? catego : recatego}/${sub !== null ? sub : resub}`);
      //  console.log(res, res.data);
      // Assuming res.data.messages is an array of messages
      const messages = res.data.messages;
      const messgop = JSON.stringify(messages.map((m) => ({
        // hdi ela wd fch zdt excel tzadt  r n   dyal rj3 str  wdkchi hhhhhhhhhhh
        label: m.replace(/[\r\n]/g, '').trim(),
        value: m.replace(/[\r\n]/g, '').trim(),
        type: 'messagez',
      })));
      const botResponses = [
        `في هذه القائمة، ما هي الرسالة التي تتطابق مع سؤالك الآن؟`,
        `الرجاء اختيار الرسالة التي تتناسب مع استفسارك في هذه القائمة.`,
        `أي من هذه الرسائل يمكن أن تكون متناسبة مع استفساراتك ؟`,
        ` الرجاء اختيار الرسالة التي تعتقد أنها مناسبة لسؤالك:`,
      ];

      const randomResponse = botResponses[Math.floor(Math.random() * botResponses.length)];

      let messg = { text: randomResponse, options: messgop };
      await typeAndDisplayBotResponse(option.value, messg, chatHistory);

    } catch (err) {
      //console.log(err);
    }
  };


  const handleModeOption = async (option) => {
    if (option.value === true) {
      setChatMode(true);
      try {
        const res = await axios.get(`${url}/bot/directed-chat/categories-name`);
        const categoryop = res.data.categoryNames.map((category) => ({
          label: category,
          value: category,
          type: 'category',
        }));
        const botResponses = [
          'في أي موضوع تريد التحدث؟',
          'الرجاء تحديد الموضوع الذي ترغب في مناقشته.',
          'مرحبًا! للحصول على مساعدتي، يُرجى الاطلاع على القائمة التالية',
          'مرحبًا! للاستفادة من خدماتي، يرجى الاطلاع على القائمة أدناه'
        ];

        const randomResponse = botResponses[Math.floor(Math.random() * botResponses.length)];

        let messg = { text: randomResponse, options: JSON.stringify(categoryop) };
        await typeAndDisplayBotResponse(option.label, messg, chatHistory);

      } catch (err) {
        //console.log(err);
      }
    } else {
      setChatMode(false);
      const botResponses = [
        'إذا كان لديك أي أسئلة أو استفسارات، فلا تتردد في طرحها؟',
        'أنا هنا لمساعدتك! هل لديك أي أسئلة أخرى؟',
        'أنا هنا لمساعدتك بأي شيء آخر، هل لديك استفسار آخر؟'
      ];

      const randomResponse = botResponses[Math.floor(Math.random() * botResponses.length)];

      let messg = { text: randomResponse };
      await typeAndDisplayBotResponse(option.label, messg, chatHistory);

    }
  };

  const handleMessageOption = async (option) => {

    try {
      const res = await axios.get(`${url}/bot/directed-chat/messages/options/${catego !== null ? catego : recatego}/${sub !== null ? sub : resub}/${option.value}`);
      //console.log(res, res.data);

      const response = res.data.response;
      const oparray = res.data.oparray;
      const op = []
      for (let i = 0; i < JSON.parse(oparray).length; i++) {
        op.push(JSON.parse(oparray)[i])
      }

      const messgop = JSON.stringify(op.map((m) => ({
        label: m.label,
        value: m.value,
        type: m.type,
      })));
      //console.log('messgop', messgop)

      let messg = { text: response, options: messgop }
      await typeAndDisplayBotResponse(option.label, messg, chatHistory);


      const maendouchop = [
        ...chatHistory,
        { user: true, text: option.label },
        { user: false, text: response, options: messgop },
      ]

      if (op.length <= 0) {
        const confirmationMessages = [
          "هل تحتاج المزيد من المساعدة؟",
          "هل تود الاستمرار؟",
          "إذا لم تكن هذه الخطوات كافية أو غير مرضية، يُرجى النقر مجددًا على 'نعم'",
          "إذا كنت بحاجة إلى المزيد من المساعدة، انقر 'نعم' مرة أخرى.",
          "شكرًا لاختيارك هذه الخيارات. إذا كنت بحاجة للمزيد من المساعدة، انقر 'نعم'.",
        ];

        const randomConfirmationMessage = confirmationMessages[Math.floor(Math.random() * confirmationMessages.length)];

        let messg = {
          text: randomConfirmationMessage,
          options: JSON.stringify([
            { label: `نعم حول ${resub}`, value: resub, type: 'resub' },
            // { label: `القائمة الرئيسية`, value: recatego, type: 'recatego' },
            { label: `القائمة الرئيسية`, value: true, type: 'mode' },
            // { label: 'لا', value: false, type: 'mode' },
          ]),
        };

        await typeAndDisplayBotResponse('', messg, maendouchop);

      }

    } catch (err) {
      //console.log(err);
    }
  };


  const handleLink = (option) => {
    try {
      const link = JSON.parse(option.value);
      window.open(link, '_blank');
    } catch (error) {
      window.open(option.value, '_blank');
    }
  };
  const handletextoption = (option) => {
    getBotResponse(option.value);
  };

  return (
    <div className="chatbot-container">
      <span id='linearebg'></span>
      <div className="chatbot-header">
        <img src="./img/Drfarid.svg" alt="Bot" />
        <h3>فريــــــد</h3>
      </div>
      <div ref={chatHistoryRef} className="chat-history">
        {chatHistory.map((message, index) => (
          <>
            <div key={index} className={`message ${message.user ? 'user' : 'bot'}`}>
              {message.user ? (
                <img src="./img/user.png" alt="User" />
              ) : (
                <img src="./img/Drfarid.svg" alt="Bot" />
              )}
              <div className="message-content">
                {/* <span> {message.text}</span> */}
                <TextRenderer styledText={message.text}/>
                {message.options && JSON.parse(message.options)[0]?.type === "messagez" ? (
                  index === chatHistory.length - 1 ? (
                    <div className="messagez">
                      {JSON.parse(message.options).map((option, indx) => (
                        <button
                          key={`${indx}_${option.label}_${option.type}`}
                          className="option-button"
                          onClick={() => handleOptionClick(option)}
                        >
                          <span>{option.label.replace(/_/g, ' ')}</span>
                        </button>
                      ))}
                    </div>
                  ) : null
                ) : null}
              </div>
            </div>
            {!message.user ? (
              <div className='message bot'>
                {message.options && JSON.parse(message.options)[0]?.type !== "messagez" ? (
                  index === chatHistory.length - 1 ? (
                    <div className="message-options">
                      {JSON.parse(message.options).map((option, indx) => (
                        <button
                          key={`${indx}_${option.label}_${option.type}`}
                          className="option-button"
                          onClick={() => handleOptionClick(option)}
                        >
                          <span>{option.label.replace(/_/g, ' ')}</span>
                        </button>
                      ))}
                    </div>
                  ) : null
                ) : null}
              </div>
            ) : null}

          </>
        ))}
      </div>

      <div className="chat-input">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder={chatMode === false ? (botTyping ? "انتظر رد البوت..." : "اكتب رسالتك...") : "يتم توجيهك الآن"}
          disabled={chatMode === true || botTyping === true}
        // className={(!chatMode || !botTyping) && window.innerWidth > 768 ? '' : 'no-focus'}
        />
        {chatMode === false ? (
          <button onClick={handleSubmit}>
            <IoIosSend size="3em" />
          </button>
        ) : (
          <button onClick={() => setChatModebutton(true)}>
            {/* <button onClick={() =>  handleModeOption({ label: 'باراكا م التوجيه', value: false, type: 'mode' })}> */}
            <GiCancel size="1em" color='red' />
          </button>
        )}

      </div>


    </div>
  );
}

export default Chat;



