import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

export default function Hm() {
  const [displayedText, setDisplayedText] = useState('');
  const [isCardFlipped, setIsCardFlipped] = useState(false);
  const [isbtn, setIsbtn] = useState(false);

  useEffect(() => {
    const renderText = async () => {
      const textToRender = `أنا هنا لمساعدتك على الفهم الأفضل ل  "القصور الكلوي"  ، والإجابة على أسئلتك الطبية.`;
      const interval = 50; // Adjust the delay as needed

      let renderedText = '';
      for (let i = 0; i < textToRender.length; i++) {
        renderedText += textToRender[i];
        setDisplayedText(renderedText + (i < textToRender.length - 1 ? '|' : ''));
        await sleep(interval);
      }

      // After renderText finishes, trigger the card animation
      setIsCardFlipped(true);
    };

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    // Trigger renderText
    renderText();

  }, []);

  useEffect(()=>{
    if (isCardFlipped) {
      setTimeout(() => {
        setIsbtn(true);
      }, 600);
    }
  },[isCardFlipped])


  useEffect(()=>{
    if (isbtn) {
      setTimeout(() => {
        setIsbtn(false);
      }, 1000);
    }
  },[isbtn])

  return (
    <div className="containerhm">
      <div class={`card ${isCardFlipped ? 'flipped' : ''}`}>
        <div class="card-inner">
          <div class="card-front">
            <img src="./img/Drfarid.svg" width='70%' height='80%' alt='' />
          </div>
          <div class="card-back">
            <img src="./img/UPDrfarid.svg" width='100%' height='100%' alt='' />
          </div>
        </div>
      </div>
      <div className="row">
        <p className='pg' lang="ar" dir="rtl">
          {displayedText}
        </p>
      </div>
      <div className="col-md-6 col-12 ">
        <div id="button1" className='w-100'>
          <Link to="/BT">
            <button id='button2' className={`btn-shine w-100 ${isbtn ? 'isbtnf' : ''}`}>
              <span className='w-100 ' lang="ar" dir="rtl">دعنا نتحدث!</span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}